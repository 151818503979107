import axios, { AxiosInstance } from 'axios';

export const timeout = 60000;

export const axiosInstance = axios.create({
  baseURL: '/api/proxy',
  timeout,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export const axiosServerInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/api`,
  timeout,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosInstanceWithoutProxy = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/api`,
  timeout,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstanceWithoutProxy.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export const Backend: { vInstance: AxiosInstance; vOneInstance: AxiosInstance } = {
  vInstance: axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}/api`,
    timeout,
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  vOneInstance: axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}/api/v1`,
    timeout,
    headers: {
      'Content-Type': 'application/json',
    },
  }),
};

for (const key in Backend) {
  if (Object.prototype.hasOwnProperty.call(Backend, key)) {
    const instance: AxiosInstance = Backend[key as keyof typeof Backend];

    instance.interceptors.request.use((config) => {
      const token = localStorage.getItem('token');

      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });
  }
}
