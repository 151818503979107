/**
 *
 * @description LocaleProvider.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 29 October 2021
 *
 */

import { AxiosResponse } from 'axios';
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ChildrenT, PaginateResponseT } from '~/@customTypes/generic';
import { LocaleT } from '~/@customTypes/locale.type';
import { axiosInstanceWithoutProxy } from '../utils/service';

export type LocaleContextT = {
  locale: string;
  setLocale: Dispatch<SetStateAction<string>>;
  locales: Partial<Array<LocaleT>>;
};
export const LocaleContext = createContext<LocaleContextT>({
  locale: 'cn',
  setLocale: (value: SetStateAction<string>) => value,
  locales: [],
});

type Props = ChildrenT;

export default function LocaleProvider({ children }: Props) {
  const [locales, setLocales] = useState<Partial<Array<LocaleT>>>([]);
  const [locale, setLocale] = useState('cn');

  useEffect(() => {
    const storage = localStorage.getItem('beccato.locale');

    if (storage) {
      setLocale(storage);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('beccato.locale', locale);
  }, [locale]);

  useEffect(() => {
    (async () => {
      const localeResponse: void | AxiosResponse<PaginateResponseT<Array<LocaleT>>> = await axiosInstanceWithoutProxy
        .get('/locales')
        .catch(() => {
          alert('Something went wrong. Please try again later.');
        });

      if (localeResponse && localeResponse.status === 200) {
        setLocales(localeResponse.data.data);
      }
    })();
  }, []);

  return <LocaleContext.Provider value={{ locale, setLocale, locales }}>{children}</LocaleContext.Provider>;
}
