/**
 *
 * @description AuthProvider.tsx
 * @author yikkok <yikok.yong@gmail.com>
 * @version 2.0.0
 * @since 29 October 2021
 *
 */

import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { ChildrenT, ResponseT } from '~/@customTypes/generic';
import { LoginResponseT } from '~/@customTypes/login.type';
import { MembershipT } from '~/@customTypes/membership.type';
import { LoaderContext, LoaderContextT } from '~/v2/context/LoaderProvider';
import LoginDialog from '../components/dialog/LoginDialog';
import RegisterDialog from '../components/dialog/RegisterDialog';
import useModal from '../hooks/useModal';
import { axiosInstanceWithoutProxy, Backend } from '../utils/service';
import { APP_VERSION } from '../utils/system.constant';

export type AuthContextT = {
  isLoggedIn: boolean;
  onSignUp: (email: string, password: string, firstname: string, lastname: string) => Promise<void>;
  onSignIn: (email: string, password: string, redirect?: string | undefined) => Promise<void>;
  onForgotPassword: (email: string) => Promise<void>;
  logout: () => Promise<void>;
  openLoginModal: () => void;
  openRegisterModal: () => void;
  membership: MembershipT | null;
};
export const AuthContext = createContext<Partial<AuthContextT>>({});

type Props = ChildrenT;

export default function AuthProvider({ children }: Props) {
  const loaderContext = useContext<LoaderContextT>(LoaderContext);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [membership, setMembership] = useState<MembershipT | null>(null);
  const router = useRouter();
  const loginModal = useModal();
  const registerModal = useModal();

  useEffect(() => {
    if (isLoggedIn) {
      localStorage.setItem('version', APP_VERSION);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const init = async () => {
      const versionStorage = localStorage.getItem('version');

      if (versionStorage !== APP_VERSION) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        localStorage.setItem('version', APP_VERSION);
        setLoggedIn(false);

        if (router.pathname.includes('my-account')) {
          router.push('/');
        }
      } else {
        const token = localStorage.getItem('token');

        if (token) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      }
    };

    init();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      setLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (process.env.NEXT_PUBLIC_ENABLE_MEMBERSHIP === 'true') {
        (async () => {
          const res: void | AxiosResponse<ResponseT<MembershipT>> = await Backend.vOneInstance
            .get('/member/membership')
            .catch(() => {
              message.error({
                content: 'Something went wrong. We are alert to it. Please try again later.',
              });
            });

          if (res && res.status === 200) {
            setMembership(res.data.data);
          } else {
            logout();
          }
        })();
      }
    }
  }, [isLoggedIn]);

  const onSignUp = async (email: string, password: string, firstname: string, lastname: string) => {
    loaderContext.setLoading?.(true);
    const res = await axiosInstanceWithoutProxy
      .post('/customer/register', {
        email,
        password,
        password_confirmation: password,
        first_name: firstname,
        last_name: lastname,
      })
      .catch(() => {
        loaderContext.setLoading?.(false);
        message.error({
          content: 'Something went wrong. We are alert to it. Please try again later.',
        });
        return;
      });

    if (res && res.status === 200) {
      loaderContext.setLoading?.(false);
      await onSignIn(email, password, '/packages');
    }
  };

  const onSignIn = async (email: string, password: string, redirect?: string | undefined) => {
    loaderContext.setLoading?.(true);
    const result: void | AxiosResponse<LoginResponseT> = await axiosInstanceWithoutProxy
      .post('/customer/login?token=true', {
        email,
        password,
      })
      .catch(() => {
        loaderContext.setLoading?.(false);
        message.error({
          content: 'Invalid Email or Password.',
        });
        return;
      });

    if (result && result.status === 200) {
      loaderContext.setLoading?.(false);
      localStorage.setItem('token', result.data.token);
      localStorage.setItem('user', JSON.stringify(result.data.data));
      loginModal.onClose();
      registerModal.onClose();
      setLoggedIn(true);

      if (redirect) {
        router.push(redirect);
      }
    }
  };

  const logout = async () => {
    loaderContext.setLoading?.(true);

    await axiosInstanceWithoutProxy.get('/customer/logout?token=true').catch(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setLoggedIn(false);
      router.push('/');

      loaderContext.setLoading?.(false);

      message.error({
        content: 'Something went wrong. We are alert to it. Please try again later.',
      });

      return;
    });

    loaderContext.setLoading?.(false);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setLoggedIn(false);
    router.push('/');
  };

  const onForgotPassword = async (email: string) => {
    loaderContext.setLoading?.(true);
    const result: void | AxiosResponse<LoginResponseT> = await axiosInstanceWithoutProxy
      .post('/customer/forgot-password', {
        email,
      })
      .catch(() => {
        loaderContext.setLoading?.(false);
        message.error({
          content: 'Something went wrong. We are alert to it. Please try again later.',
        });
        return;
      });

    if (result && result.status === 200) {
      loaderContext.setLoading?.(false);
      message.success({
        content: result.data.message || 'Reset password link had sent to your email account.',
      });
    }
  };

  const openLoginModal = () => {
    loginModal.onOpen();
    registerModal.onClose();
  };

  const openRegisterModal = () => {
    registerModal.onOpen();
    loginModal.onClose();
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        onSignUp,
        onSignIn,
        onForgotPassword,
        logout,
        openLoginModal,
        openRegisterModal,
        membership,
      }}
    >
      {children}

      <LoginDialog open={loginModal.open} onClose={loginModal.onClose} onCancel={loginModal.onClose} />
      <RegisterDialog open={registerModal.open} onClose={registerModal.onClose} onCancel={registerModal.onClose} />
    </AuthContext.Provider>
  );
}
